// Halloween.js
export const Halloween = function (wispFrequency, ghostFrequency, scaleFactor) {
  const canvas = document.getElementById("snow");
  const ctx = canvas.getContext("2d");
  ctx.imageSmoothingEnabled = false;

  let wisps = [];
  let ghosts = [];
  let lastGhostSpawnTime = 0; // Track the last ghost spawn time

  // Load sprite sheets
  const wispSpriteSheet1 = loadImage("halo/wow1.png"); // Wisp sprite sheet 1
  const wispSpriteSheet2 = loadImage("halo/wow2.png"); // Wisp sprite sheet 2
  const ghostSpriteSheet = loadImage("halo/ghost.png"); // Ghost sprite sheet

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  function loadImage(src) {
    const img = new Image();
    img.src = src;
    return img;
  }

  function createWisp() {
    const wispSpriteSheet =
      Math.random() < 0.5 ? wispSpriteSheet1 : wispSpriteSheet2;

    let wisp = {
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      scale: scaleFactor,
      alpha: 0,
      fadingIn: true,
      frame: 0,
      spriteSheet: wispSpriteSheet,
      frameCount: wispSpriteSheet === wispSpriteSheet1 ? 4 : 5,
      frameTime: 0,
      frameDuration: 100, // Time per frame in milliseconds
    };
    wisps.push(wisp);
  }

  function createGhost() {
    let direction = Math.random() < 0.5 ? -1 : 1;

    let ghost = {
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      scale: scaleFactor,
      alpha: 0,
      fadingIn: true,
      frame: 0,
      frameCount: 5, // Number of frames in ghost sprite sheet
      frameTime: 0,
      frameDuration: 100, // Time per frame in milliseconds
      direction: direction,
      flip: direction === 1,
      duration: Math.random() * 2000 + 4000, // Lasts between 4 to 6 seconds
    };

    // Check if the new ghost is too close to existing ghosts
    const minDistance = 100; // Minimum distance between ghosts
    const isTooClose = ghosts.some((existingGhost) => {
      const distance = Math.hypot(
        existingGhost.x - ghost.x,
        existingGhost.y - ghost.y
      );
      return distance < minDistance;
    });

    // If the new ghost is too close to any existing ghost, do not add it
    if (!isTooClose) {
      ghosts.push(ghost);
      lastGhostSpawnTime = Date.now(); // Update the last spawn time
    }
  }

  function updateWisps() {
    wisps.forEach((wisp, index) => {
      if (wisp.fadingIn) {
        wisp.alpha += 0.01;
        if (wisp.alpha >= 0.98) wisp.fadingIn = false;
      } else {
        wisp.alpha -= 0.01;
        if (wisp.alpha <= 0) {
          wisps.splice(index, 1);
        }
      }

      // Random movement
      wisp.x += (Math.random() - 0.5) * 0.5;
      wisp.y += (Math.random() - 0.5) * 0.5;

      // Update frame for wisp
      wisp.frameTime += 16; // Assuming ~60fps
      if (wisp.frameTime >= wisp.frameDuration) {
        wisp.frame = (wisp.frame + 1) % wisp.frameCount; // Cycle through frames
        wisp.frameTime = 0;
      }
    });
  }

  function updateGhosts() {
    ghosts.forEach((ghost, index) => {
      // Duration check for fading in/out
      if (ghost.fadingIn) {
        ghost.alpha += 0.02;
        if (ghost.alpha >= 0.98) ghost.fadingIn = false;
      } else {
        ghost.alpha -= 0.006; // Slightly slower fading out
        if (ghost.alpha <= 0) {
          ghosts.splice(index, 1);
        }
      }

      // Ghost movement
      ghost.x += ghost.direction * 1.5; // Move ghost left or right
      ghost.y += Math.sin(Date.now() / 300) * 0.9; // Slight vertical oscillation

      // Update frame for ghost
      ghost.frameTime += 16; // Assuming ~60fps
      if (ghost.frameTime >= ghost.frameDuration) {
        ghost.frame = (ghost.frame + 1) % ghost.frameCount; // Cycle through frames
        ghost.frameTime = 0;
      }
    });
  }

  function draw() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw wisps
    wisps.forEach((wisp) => {
      ctx.globalAlpha = wisp.alpha;
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(
        wisp.spriteSheet,
        wisp.frame * 32, // X coordinate for the frame
        0, // Y coordinate (assuming single row)
        32, // Width of the frame
        32, // Height of the frame
        wisp.x,
        wisp.y,
        32 * wisp.scale, // Scaled width
        32 * wisp.scale // Scaled height
      );
    });

    // Draw ghosts
    ghosts.forEach((ghost) => {
      ctx.globalAlpha = ghost.alpha;
      ctx.save();
      ctx.imageSmoothingEnabled = false;
      if (ghost.flip) {
        ctx.translate(ghost.x + 32 * ghost.scale, ghost.y); // Adjust for flipping
        ctx.scale(-1, 1);
        ctx.drawImage(
          ghostSpriteSheet,
          ghost.frame * 32,
          0,
          32,
          40, // Height of the ghost frame
          0,
          0,
          32 * ghost.scale,
          40 * ghost.scale
        );
      } else {
        ctx.drawImage(
          ghostSpriteSheet,
          ghost.frame * 32,
          0,
          32,
          40, // Height of the ghost frame
          ghost.x,
          ghost.y,
          32 * ghost.scale,
          40 * ghost.scale
        );
      }
      ctx.restore();
    });

    ctx.globalAlpha = 1; // Reset global alpha
  }

  function animate() {
    updateWisps();
    updateGhosts();
    draw();
    requestAnimationFrame(animate);
  }

  // Create wisps at intervals with sparser distribution
  setInterval(() => {
    if (wisps.length < 10 && Math.random() < wispFrequency) {
      createWisp();
    }
  }, 1000 / 30);

  // Create ghosts at intervals with more controlled appearance
  setInterval(() => {
    const now = Date.now();
    if (
      ghosts.length < 2 &&
      Math.random() < ghostFrequency &&
      now - lastGhostSpawnTime >= 3000
    ) {
      // 3 seconds cooldown
      createGhost();
    }
  }, 1000 / 60); // Increase the interval between ghost spawns to reduce frequency

  animate();
};
