function Bookmark(props) {
  return (
    <div className="component Bookmark">
      <div
        className="container nes-container with-title is-centered is-dark"
        id="nikki"
      >
        <p className="title">SPARK NIKKI</p>
        <img src={props.icon} className="sprite"></img>
      </div>
      <div
        className="container nes-container with-title is-centered is-dark bcontent"
        style={{ flexGrow: "2" }}
      >
        <p className="title">BOOKMARK</p>
        <ul>
          <li
            onClick={() => {
              window.location.reload();
            }}
          >
            <a>MAIN PAGE</a>
          </li>
          <li
            onClick={() => {
              props.setShowDetail(false);
              props.setPage("contacts");
            }}
          >
            <a>ABOUT</a>
          </li>
          <li
            onClick={() => {
              props.setShowDetail(false);
              props.setPage("portfolio");
            }}
          >
            <a>PORTFOLIO</a>
          </li>
          <li
            onClick={() => {
              props.setShowDetail(false);
              props.setPage("archive");
            }}
          >
            <a>ARCHIVE</a>
          </li>
          {/* <li onClick={() => props.setPage("adventures")}>
            <a>ADVENTURES</a>
          </li>
          <li onClick={() => props.setPage("food")}>
            <a>FOOD</a>
          </li>
          <li onClick={() => props.setPage("other photos")}>
            <a>OTHER</a>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default Bookmark;
