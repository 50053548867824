import { getContentByMonth, getPortfolio, getAmazonImage } from "./api";
import React, { useState } from "react";

let containerStyle = { flexGrow: "1", paddingBottom: "20px" };

function Content(props) {
  const [wholeGallery, setWholeGallery] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [galleryDetailIndex, setGalleryDetailIndex] = useState(0);
  const [gallery, setGallery] = useState([]);

  if (props.content.c) {
    let content = props.content.c[props.content.i];
    switch (props.page) {
      case "main":
        return (
          <div style={containerStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 0px 8px 0px",
              }}
            >
              <div
                style={{
                  height: "2px",
                  backgroundColor: "white",
                  flexGrow: "1",
                }}
              ></div>
              <div className="contentDate">└ {formatDate(content.date)} ┘</div>
              <div
                style={{
                  height: "2px",
                  backgroundColor: "white",
                  flexGrow: "1",
                }}
              ></div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: content.post }}
              className="content allowSelect"
            ></div>
          </div>
        );
      case "contacts":
        return (
          <div style={(containerStyle, { display: "block" })}>
            <div style={{ paddingBottom: "8px" }}>ABOUT</div>
            <div style={{ fontSize: "14px", textAlign: "left" }}>
              I'm a weeaboo who enjoys sightseeing! I love Touhou, favourite
              anime is Symphogear, favourite manga is Spectral Wizard/stuff by
              Mozocry. Best girls are Satori Komeiji and Hibiki Tachibana.
            </div>
            <div
              style={{ fontSize: "14px", textAlign: "left", marginTop: "20px" }}
            >
              Uhh my birthdate is 22-06-1995 and I was born in Rome and I'm half
              Mexican, currently living near Verone for work. Not sure what else
              to say here, open for suggestions!
            </div>
            <ul>
              <li>
                <a href="https://twitter.com/LSpark_zwz">Twitter: LSpark_zwz</a>
              </li>
              <li>
                <a href="https://discordapp.com/users/85022247223316480">
                  Discord: lspark
                </a>
              </li>
            </ul>
            <div>Me!</div>
            <img
              style={{ width: "200px" }}
              src="https://lsparkrandom.s3.us-east-1.amazonaws.com/autismo.jpg"
            ></img>
          </div>
        );
      case "portfolio":
        let galleryChunks = [];
        if (gallery.length === 0) {
          try {
            getPortfolio((data) => {
              const chunkSize = 48;
              let galleryTmp = [];
              data.map((v, i) => {
                v["i"] = i;
                return v;
              });
              if (data.length > 0) {
                for (let i = 0; i < data.length; i += chunkSize) {
                  const chunk = data.slice(i, i + chunkSize);
                  galleryTmp.push(chunk);
                }
              }
              setGallery(galleryTmp);
              setWholeGallery(data);
            });
          } catch {}
        } else {
          galleryChunks = [];
          const chunkSize = window.innerWidth > 2000 ? 4 : 3;
          for (let i = 0; i < gallery[galleryIndex].length; i += chunkSize) {
            const chunk = gallery[galleryIndex].slice(i, i + chunkSize);
            galleryChunks.push(chunk);
          }
        }
        if (props.showDetail) {
          return (
            <Detail
              galleryDetailIndex={galleryDetailIndex}
              setGalleryDetailIndex={setGalleryDetailIndex}
              setShowDetail={props.setShowDetail}
              gallery={wholeGallery}
            ></Detail>
          );
        } else {
          return (
            <div className="portfolioContainer">
              {galleryChunks.map((chunk) => (
                <div className="portfolioGallery">
                  {chunk.map((element) => {
                    let content = JSON.parse(element.content);
                    return (
                      <a
                        className="portfolioThumbnailWrapper"
                        href={content.media[0]}
                        onClick={(e) => {
                          e.preventDefault();
                          setGalleryDetailIndex(element.i);
                          props.setShowDetail(true);
                        }}
                      >
                        <img
                          className="portfolioThumbnail"
                          src={content.media[0]}
                          loading="lazy"
                        ></img>
                      </a>
                    );
                  })}
                </div>
              ))}
            </div>
          );
        }

      case "archive":
        const startDate = new Date("2023-01-01");
        const cDate = new Date();
        const months = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        return (
          <div style={containerStyle}>
            <div style={{ paddingBottom: "8px" }}>ARCHIVE</div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                gap: "10px",
              }}
            >
              {renderArchive(startDate, cDate, months, props)}
            </div>
          </div>
        );
      case "adventures":
        return <div style={containerStyle}>adventures</div>;
      case "food":
        return <div style={containerStyle}>food</div>;
      case "other photos":
        return <div style={containerStyle}>other photos</div>;
      case "archive content":
        return <div>popo</div>;
      default:
        return <div style={containerStyle}>ababva</div>;
    }
  }
}

const renderArchive = (startDate, cDate, months, props) => {
  let archive = [];
  for (let i = startDate.getFullYear(); i <= cDate.getFullYear(); i++) {
    let m = [];
    if (i === startDate.getFullYear()) {
      if (startDate.getFullYear() === cDate.getFullYear()) {
        m = months.slice(startDate.getMonth(), cDate.getMonth() + 1);
      } else {
        m = months.slice(startDate.getMonth(), 12);
      }
    } else if (i === cDate.getFullYear()) {
      m = months.slice(0, cDate.getMonth() + 1);
    } else {
      m = months;
    }

    let md = [];
    for (let j = 0; j < m.length; j += 3) {
      let chunk = m.slice(j, j + 3);
      let cres = [];
      chunk.forEach((c) => {
        cres.push(
          <div
            className="pointer"
            onClick={() => {
              let m = months.indexOf(c) + 1;
              if (m < 10) {
                m = "0" + m;
              }
              try {
                getContentByMonth(i, m, (data) => {
                  console.log(data);
                  if (data.length > 0) {
                    props.setLVisibility("hidden");
                    if (data.length > 1) {
                      props.setRVisibility("visible");
                    }
                    props.setCurrentContent({ c: data.reverse(), i: 0 });
                    props.setPage("main");
                  }
                });
              } catch {}
            }}
          >
            {c}
          </div>
        );
      });

      md.push(<div style={{ display: "flex", gap: "12px" }}>{cres}</div>);
    }

    archive.push(
      <div>
        <div>{i}</div>
        <div>{md}</div>
      </div>
    );
  }
  return archive.reverse();
};

const formatDate = (date) => {
  date = date.split(" ")[0];
  let day = new Date(
    date.split("-")[1] + "/" + date.split("-")[2] + "/" + date.split("-")[0]
  ).toLocaleDateString("en-US", { weekday: "long" });

  return (
    day +
    " " +
    date.split("-")[2] +
    "/" +
    date.split("-")[1] +
    "/" +
    date.split("-")[0]
  );
};

export default Content;

function Detail(props) {
  let item = props.gallery[props.galleryDetailIndex];
  let media = JSON.parse(item.content).media[0];
  return (
    <div className="detailContainer">
      <div className="galleryNav">
        <div className="box">
          <div className="X" onClick={() => props.setShowDetail(false)}>
            X
          </div>
        </div>
        <div className="box">
          <div className="dTitle">{item.title}</div>
        </div>
        <div className="galleryNavArrows box">
          <div className="arrows">
            <div
              className="arrow"
              id="a4"
              style={{
                fontSize: "2vw",
                fontFamily: "PixArrows",
                visibility:
                  props.galleryDetailIndex === 0 ? "hidden" : "visible",
              }}
              onClick={() => {
                props.setGalleryDetailIndex(props.galleryDetailIndex - 1);
              }}
            >
              4
            </div>
            <div
              className="arrow"
              id="a5"
              style={{
                fontSize: "2vw",
                fontFamily: "PixArrows",
                visibility:
                  props.galleryDetailIndex === props.gallery.length - 1
                    ? "hidden"
                    : "visible",
              }}
              onClick={() => {
                props.setGalleryDetailIndex(props.galleryDetailIndex + 1);
              }}
            >
              5
            </div>
          </div>
        </div>
      </div>
      <img className="detailImage" src={media}></img>
      <div
        style={{ maxWidth: "100%", paddingTop: "20px" }}
        dangerouslySetInnerHTML={{ __html: item.comment }}
      ></div>
    </div>
  );
}
