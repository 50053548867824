import "nes.css/css/nes.min.css";
import { insertVisitorPost } from "./api";

function GuestbookForm(props) {
  return (
    <div
      className="cover"
      style={{ display: props.GBformDisplay }}
      onClick={() => props.setGBformDisplay("none")}
    >
      <div className="center-div" onClick={(e) => e.stopPropagation()}>
        <div className="container nes-container is-dark z is-centered">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="four"
              style={{
                fontSize: "0.4vw",
                textAlign: "left",
                paddingBottom: "8px",
              }}
            >
              Username:
            </div>
            <textarea
              id="visitor_username"
              maxLength="281"
              spellCheck="false"
              className="six"
              style={{
                width: "25vh",
                backgroundColor: "transparent",
                resize: "none",
                border: "none",
                color: "white",
                outline: "none",
                fontSize: "0.6vw",
              }}
              defaultValue="Anon"
            ></textarea>
            <div
              className="four"
              style={{
                fontSize: "0.4vw",
                textAlign: "left",
                paddingBottom: "8px",
              }}
            >
              Message:
            </div>
            <textarea
              id="visitor_message"
              maxLength="281"
              spellCheck="false"
              className="six"
              style={{
                height: "20vh",
                width: "25vh",
                backgroundColor: "transparent",
                resize: "none",
                border: "none",
                color: "white",
                outline: "none",
                fontSize: "0.6vw",
              }}
              defaultValue="Please don't abuse this form..."
            ></textarea>
          </div>
        </div>
        <div
          className="formButtons"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            className="container nes-container is-dark z is-centered button"
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => props.setGBformDisplay("none")}
          >
            NEVERMIND
          </div>
          <div
            className="container nes-container is-dark z is-centered button"
            style={{ display: "flex", justifyContent: "center" }}
            onClick={() => {
              insertVisitorPost(
                document.getElementById("visitor_message").value,
                document.getElementById("visitor_username").value,
                (err) => {
                  if (err) console(err);
                  alert(
                    "Thank you for posting! If you don't see your comment please refresh the page after a few seconds"
                  );
                  props.setVisitorContent();
                  props.setGBformDisplay("none");
                }
              );
            }}
          >
            POST
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestbookForm;
